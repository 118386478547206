import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { gtagEvent } from "~/utils/analytics/gtag";
import { deleteLoggingIn, getLoggingIn } from "~/utils/app/page-restoration";
import { useRootData } from "~/utils/data/useRootData";
import { usePrevious } from "~/utils/misc";

const AccountContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useAccountContextVals = () => {
  const [open, setOpen] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const rootData = useRootData();
  const [userCreatedAt, setUserCreatedAt] = useState(rootData?.user?.createdAt);
  const prevCreated = usePrevious({ userCreatedAt });
  const [hasRendered, setHasRendered] = useState(false);
  const [currentInterest, setCurrentInterest] = useState(null);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    setUserCreatedAt(rootData?.user?.createdAt);
  }, [rootData?.user?.createdAt]);

  useEffect(() => {
    if (hasRendered) {
      if (prevCreated?.userCreatedAt !== userCreatedAt) {
        // if the user was created in the last 1 minute, trigger the signup tag
        if (userCreatedAt && dayjs().diff(dayjs(userCreatedAt), "second") < 60) {
          gtagEvent("sign_up", {});
        } else if (userCreatedAt) {
          gtagEvent("login", {});
        }
      }
    }
  }, [userCreatedAt, hasRendered]);

  useEffect(() => {
    //if the user should have the window open on refresh, open it
    const isLoggingIn = getLoggingIn();
    const hasSub = rootData.hasSub;
    if (isLoggingIn && !hasSub) {
      openAccount("popup", "refresh");
    } else if (hasSub) {
      deleteLoggingIn();
    }
  }, []);

  const openCancel = () => {
    setCancelOpen(true);
  };

  const closeCancel = () => {
    setCancelOpen(false);
  };

  const openAccount = (triggeredBy?: string, referenceCTA?: string) => {
    gtagEvent("select_promotion", {
      creative_slot: triggeredBy,
      creative_name: referenceCTA?.toLowerCase().replace(" ", "_"),
    });
    setCurrentInterest(referenceCTA);
    setOpen(true);
  };

  const closeAccount = () => {
    setOpen(false);
  };

  const openPayments = () => {
    setPaymentsOpen(true);
  };

  const closePayments = () => {
    setPaymentsOpen(false);
  };

  const openSubscriptions = () => {
    setSubscriptionsOpen(true);
  };

  const closeSubscriptions = () => {
    setSubscriptionsOpen(false);
  };

  const setAccountForgotPassword = () => {
    setForgotPassword(true);
  };

  const clearAccountForgotPassword = () => {
    setForgotPassword(false);
  };

  return {
    open,
    setOpen,
    openAccount,
    closeAccount,
    setAccountForgotPassword,
    clearAccountForgotPassword,
    forgotPassword,
    openPayments,
    closePayments,
    paymentsOpen,
    subscriptionsOpen,
    openSubscriptions,
    closeSubscriptions,
    cancelOpen,
    openCancel,
    closeCancel,
    currentInterest,
  };
};

const AccountContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const accountContext = useAccountContextVals(props);

  return <AccountContext.Provider value={{ ...accountContext }}>{props.children}</AccountContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useAccountContext = () => useContext(AccountContext);
const useAccountSelectorContext = (selector: any) => {
  return useContextSelector(AccountContext, selector);
};
export { AccountContextProvider, useAccountContext, useAccountSelectorContext };
